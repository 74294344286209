<template>
  <div class="privacy">
    <!-- header -->
    <nav-header />
    <!-- content -->
    <div class="privacy-container">
      <div class="container-inner">
        <div class="l-title">导言</div>
        <div class="l-text">感谢您选择PDF在线转换会员服务！本协议是您与琴傲信息之间关于您使用PDF在线转换会员服务（以下简称为“本服务”）所订立的有效契约，具有法律效力。在使用本服务前，了解我们提供的服务以及我们对您的期望是非常必要的，我们将尽可能的使用通俗易懂的语言全面的向您展现本协议的内容，我们也希望您花一些时间熟悉本协议以下条款。</div>
        <div class="l-text"><span class="t_bold">我们在此善意地提醒您，本协议中与您的权益（可能）存在重大关系的条款，我们已采用字体加粗的方式来特别提醒您，请您留意重点查阅。</span>您应当在确认充分阅读本协议字体加粗条款，明确理解了该等字体加粗条款的前提下使用本服务。</div>
        <div class="l-text">如果您对本协议及本服务有任何问题、疑惑，请随时联系我们：</div>
        <div class="l-text">专设的邮件通道：我们专门设立了dannapdf@163.com 邮箱，您可以通过发送电子邮件至该邮箱与我们联系</div>
        <div class="l-text">本协议将帮助您了解以下内容：</div>
        <div class="l-text">我们提供的PDF在线转换会员服务</div>
        <div class="l-text">PDF在线转换会员服务中的软件、网站</div>
        <div class="l-text">PDF在线转换会员服务的限制</div>
        <div class="l-text">我们的保证与声明</div>
        <div class="l-text">违约行为及责任</div>
        <div class="l-text">法律管辖</div>
        <div class="l-text">关于本协议</div>
        <div class="l-text">以下术语的解释是为了您更加清晰地了解本协议的内容：</div>
        <div class="l-text t_bold">1. “琴傲信息”/“我们”：“ 上海琴傲信息软件有限公司”。</div>
        <div class="l-text">
          <span class="t_bold">2. “您”/“用户”：指有偿（包括但不限于付费、邀请注册、签到、参与我们及/或与我们有合作关系的第三方组织的活动、赠送等多种方式获取）</span>使用本服务中的任何部分或全部的自然人用户。
        </div>
        <div class="l-text">
          <span class="t_bold">3. “会员特权”：</span>指PDF在线转换会员可享受的会员权益及特色服务，包括但不限于功能特权、PDF特权等，具体以网站上公布的内容为准。您知悉并同意，我们有权增加、删减、替换或调整会员可享受的会员特权的种类、数量、时限、内容以及提供形式。
        </div>
        <div class="l-text">
          <span class="t_bold">4. “会员期限”：</span>以您自行选择并支付相应费用购买的期限为准，您可以登录个人中心页面查询。
        </div>
        <div class="l-t-title">一、我们提供的PDF在线转换会员服务</div>
        <div class="l-text">1. 我们或我们的关联公司及/或与我们或我们的关联公司存在合作关系的第三方合作伙伴推出的供您以琴傲信息在线服务账号登录使用的服务及产品均属于本服务。</div>
        <div class="l-text">2. 主要包括：截止目前已经推出的PDF相关格式转换、合并、等PDF特权、以及纯净无广告等身份特权，具体服务以我们实际提供的为准。</div>
        <div class="l-text">3. 我们仅许可您在中国大陆地区范围内使用本服务。</div>
        <div class="l-text t_bold">4. 我们有权根据经营策略安排，在不同客户端向您提供不同的服务种类、服务内容。</div>
        <div class="l-text">5. 我们提供的服务为收费服务，使用该服务前您须支付对应的服务费用。具体收费标准以网站上公布的内容为准。</div>
        <div class="l-text">6. 我们一直在开发新的技术和功能，以期改进本服务，为实现持续性的改进，我们会添加或移除某些功能，提高或降低服务限制，开始提供新服务，或者停止提供旧服务。同时，我们可在本服务包含的任何具体服务基础上，推出需您另行付费购买的增值功能及服务，届时，如您需获得该等增值功能及服务，应按我们届时公布的该等增值功能及服务的价格，另行向我们支付购买费用。</div>
        <div class="l-text">7. 我们新增的子服务将有可能附带特别适用于该项子服务的协议，请您在使用该新增子服务前，仔细阅读并同意该项子服务所附带的协议（如有）。<span class="t_bold">如本协议与任何针对某项新增子服务而添加的特别约定、协议不一致的，均优先适用针对新增子服务而添加的特别约定及协议。</span></div>
        <div class="l-text">8. 本服务的开通最高期限会受到一定限制，具体以相关服务页面公布提示的为准。</div>
        <div class="l-t-title"></div>
        <div class="l-text">二、PDF在线转换会员服务中的软件</div>
        <div class="l-text t_bold">1. 如您在使用本服务的过程中需要安装使用我们提供的软件，则您应另行向我们支付使用该等软件对应的授权费用（如有）。在此特别说明，本服务仅能通过以个人用户作为授权对象的PDF在线转换 软件使用。</div>
        <div class="l-text t_bold">2. 为了改善用户体验、保证服务的安全性，我们可能会对我们提供的软件进行更新，建议您接受更新后的版本，否则您可能无法正常使用我们提供的服务。</div>
        <div class="l-text t_bold">3. 因任何影响网络正常浏览的不可抗力原因、计算机系统问题、黑客攻击、计算机病毒侵入或发作的原因而造成的用户资料泄露、丢失、被盗用或被篡改等，我们均获得免责。</div>
        <div class="l-t-title">三、PDF在线转换会员服务的限制</div>
        <div class="l-text t_bold">1. 本服务仅供您自行使用，您在任何时候均无权以任何方式将本服务包含的任何部分或全部予以转让、转授权、分授权给您以外的任何一方。如本款约定与本协议其他条款约定发生冲突，均以本款约定为准。</div>
        <div class="l-text">2. 您知悉并认可，本服务所包含的部分子服务会对您在线使用该等服务的设备数量设置上限，例如本服务所包含的PDF转Word服务，仅可供您在累计不多于5台电脑上在线使用，如超出前述设备数量的上限，琴傲信息有权冻结该账号。具体您使用本服务时所受到的有关设备类型、数量等的限制，以您届时具体使用本服务时显示的提示为准。</div>
        <div class="l-t-title">四、我们的保证与声明</div>
        <div class="l-text t_bold">1. 有关第三方行为的声明：如您使用本服务遭受的损失系由于我们之外的第三方提供的服务及资源所致，您只能向该第三方追索赔偿，我们将尽可能地予以协助，但对此不承担任何责任。</div>
        <div class="l-text t_bold">2. 本服务的部分子服务可能来源于其它第三方合作伙伴，该内容可能因我们与第三方合作伙伴合作协议的结束、终止而取消，您明确知晓上述不确定性，一旦该部分子服务因我们与第三方合作伙伴合作协议的结束、终止而取消，如该部分子服务是免费提供给您使用的，那您将不能再继续使用该部分服务；如果您为获得该部分子服务已向我们支付费用，我们将尽力与该第三方协商，如该第三方同意，您可以使用至该部分子服务的费用耗完为止；如该第三方不同意您继续使用该部分子服务，那么我们将会根据您实际使用的情况，扣除一定比例的服务费用，将剩余费用退还与您，我们有权决定扣除服务费用的比例。</div>
        <div class="l-t-title">五、违约行为及责任</div>
        <div class="l-text">1. 您违反本协议任何约定的行为均属于违约行为，应按照本协议中违约及处理的相关约定承担违约责任。</div>
        <div class="l-t-title">六、法律管辖</div>
        <div class="l-text">1. 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</div>
        <div class="l-text">2. 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向上海琴傲信息软件有限公司所在地有管辖权的人民法院提起诉讼。</div>
        <div class="l-t-title">七、关于本协议</div>
        <div class="l-text">1. 即便您没有点击同意本协议，下述行为也将视为您对本协议及琴傲信息在线服务系列协议所有条款的认可：</div>
        <div class="l-text">1.1. 点击登录本服务；</div>
        <div class="l-text">1.2. 实际使用本服务；</div>
        <div class="l-text">1.3. 实际使用我们连同本服务一并提供的任何产品或服务（如有）；</div>
        <div class="l-text">1.4. 实际使用第三方连同本服务一并提供的任何产品或服务（如有）。</div>
        <div class="l-text">2. 以本协议的最新的更新版本（如有）的约定为准。</div>
      </div>
    </div>
    <!-- footer -->
    <copy-right-footer></copy-right-footer>
  </div>
</template>

<script>
import CopyRightFooter from '@/components/CopyRightFooter.vue';
import NavHeader from "@/components/NavHeader";
export default {
  components: {
    CopyRightFooter,
    NavHeader
  }
};
</script>

<style lang="scss" scoped>
.privacy {
  .privacy-container {
    width: 100%;
    min-width: 1200px;
    padding: 70px 50px 0 50px;
    box-sizing: border-box;
    background: #f6f6f6;
    .container-inner {
      width: 1200px;
      min-width: 1200px;
      background-color: #fff;
      margin: 0 auto;
      padding: 50px 100px 100px;
      box-sizing: border-box;
      .l-title {
        margin: 0 auto;
        font-size: 32px;
        font-weight: 500;
        font-family: Microsoft YaHei UI;
        margin-bottom: 20px;
        margin-top: 40px;
        text-align: center;
      }
      .t_bold {
        font-weight: bold;
        color: #000000 !important;
      }
      .l-version {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 50px;
        color: #666666;
        text-align: center;
        font-family: Microsoft YaHei UI;
      }
      .l-t-title {
        font-size: 16px;
        font-weight: 600;
        font-family: Microsoft YaHei UI;
        margin-bottom: 20px;
        margin-top: 40px;
      }
      .l-text {
        line-height: 30px;
        color: #666;
        font-size: 14px;
        font-family: Microsoft YaHei UI;
        text-indent: 2em;
      }
    }
  }
}
</style>
